import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals.js";
import "./assets/scss/main.scss";

ReactDOM.render(<App />, document.getElementById("root"));


reportWebVitals();
