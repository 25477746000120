import React from "react";
import Header from "../../components/header/HeaderRTL.jsx";
import Slider from "../../components/slider/SliderRtlAnimation.jsx";
import About from "../../components/about/AboutAnimationRTL.jsx";
import ContactHeb from "../../components/contact/ContactHeb.jsx";
import ContactInfoHeb from "../../components/contact/ContactInfoHeb.jsx";
import FooterHeb from "../../components/footer/FooterHeb.jsx";
import useDocumentTitle from "../../components/useDocumentTitle.js";

const HomeHeb = () => {
  useDocumentTitle(
    "מאיה אלט | UX / UI ועיצוב אתרים"
  );
  document.body.classList.add("theme-dark");
  return (
    <div className="main-left rtl-theme">
      <Header />
      {/* End Header Section */}

      <Slider />
      {/* End Slider Section */}


      <section id="projects" className="section">
            <div className="container">
                <div className="title">
                    <h3>הפרוייקטים שלי</h3>
                </div>
                <div class="row">
                    <div class="col-md-6 m-15px-tb aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200">
                        <div class="blog-grid">
                            <div class="blog-img">
                              <a href="/cookitHeb"><img src="https://res.cloudinary.com/dw3voimry/image/upload/v1660636048/Maya-web/cook-it-finished_fl2nve.webp" alt="blog post"/></a>
                            </div>
                            <div class="blog-info">
                                <p class="meta">29 פברואר 2022 - מאת מאיה אלט</p>
                                <p className="h4">אפליקציה ואתר | בישול ארוחה בבית בפשטות שלא הכרתם </p>
                                <a href="/cookitHeb">לפרוייקט המלא</a>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-6 m-15px-tb aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                            <div class="blog-grid">
                                <div class="blog-img">
                                    <a href="/pawfactHeb"><img src="https://res.cloudinary.com/dw3voimry/image/upload/v1660206120/Maya-web/pawfact-background.webp" alt="pawect-post"/></a>
                                </div>
                                    <div class="blog-info">
                                    <p class="meta">02 אפריל 2022 - מאת מאיה אלט</p>
                                        <p className="h4 pt-3">אפליקציה ומערכת | מעקב וטרינר קבוע אחר בריאות חיות מחמד</p>
                                        <a href="/pawfactHeb">לפרוייקט המלא</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
      {/* End Portfolio Section */}


      <About />
      {/* End About Section */}

      <section id="contact" className="section theme-dark dark-bg">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 col-xl-4 m-15px-tb"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <ContactInfoHeb />
            </div>
            {/* End Contact info */}

            <div
              className="col-lg-7 ml-auto m-15px-tb"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="contact-form">
                <h4>צור קשר</h4>
                <ContactHeb />
              </div>
            </div>
            {/* End contact form */}
            {/* End Col */}
          </div>
        </div>
      </section>
      {/* End Contact Section */}

      <footer className="footer white">
        <div className="container">
          <FooterHeb />
        </div>
      </footer>
      {/* End Contact Section */}
    </div>
  );
};

export default HomeHeb;
