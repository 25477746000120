import React from "react";

const ServiceContent = [
  {
    icon: "icon-target",
    title: "UX Design",
    descriptions: `In the end, even if the product is Superb and its user experience is unsatisfactory, the clients won't use it.
    I see the user experience as an art sliced before my eyes. 
    My speciality is placing the parts in the appropriate and correct way in order for them to be integrated into one product that is second to none.
    Conducting market research, target audience and meeting the most stringent standards allow me to deliver the perfect product for any project.`,
    delayAnimation: "200",
  },
  {
    icon: "icon-desktop",
    title: "Web Design",
    descriptions: `Website design has long since become more than just a characterization and accessibility page for the target audience.
    For the website to be relevant, we need to design our website to suit mobile devices as the primary view.
    Therefore we must conduct the research accordingly, evaluate the target audience to understand the goals of the project, and focus on the user experience on the site in addition to its design.`,
    delayAnimation: "",
  },
  {
    icon: "icon-mobile",
    title: "App Design",
    descriptions: `The main difference between a good app and a bad app design is usually the quality of its user experience (UX). Nowadays, mobile users anticipate much more from an app:
    Ease of use.
    Fast loading time.
    Design for trust.
    Delight during the interactions. 
    Custome made experience.
    If you want your app to thrive, you have to consider a UX design to be not just a minor aspect of your app but an essential component of product strategy.`,
    delayAnimation: "400",
  }
];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className="col-md-6 col-lg-4 my-3"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-01">
              <div className="icon">
                <i className={`icon ${val.icon}`}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
