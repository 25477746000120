import React from "react";
import Social from "../Social";
import Testimonials from "../testimonial/TestimonialAnimation";
import ServicesRTL from "../service/ServiceAnimationRTL";
import Awards from "../award/AwardsAnimation";

const AboutRTL = () => {
  return (
    <>
      <section id="about" className="text-white section theme-light dark-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-duration="1200">
              <div className="about-me">
                <div className="img">
                  <div className="img-in">
                    <img src="img/about/about-me.png" alt="about" />
                  </div>

                  <Social />

                  {/* End social icon */}
                </div>
                {/* End img */}
                <div className="info">
                  <p>מעצבת UX</p>
                  <h3>מאיה אלט</h3>
                </div>
                {/* End info */}
              </div>
              {/* End about-me */}
            </div>
            {/* End col */}

            <div
              className="col-lg-7 ml-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="about-info">
                <div className="title">
                  <h3>אודות</h3>
                </div>
                <div className="about-text">
                  <p>
                  עם החזון שלך, העיצוב שלי, וכמות נכבדת של קפה נוכל ליצור חוויה מענגת שהלקוחות שלך יאהבו                  </p>
                  <p>
                  אני מעצבת UX פרילנסרית עם תשוקה לשלמות.
אני מתל אביב, ישראל.
אני מעצבת ויוצרת אלמנטים באינטרנט עבור אנשים מדהימים ברחבי העולם.
ההתלהבות שלי היא מאלמנטים מינימליסטיים ויפים שאנשים יוכלו להשתמש בהם ולהבין.
                  </p>
                </div>
                <div className="info-list">
                  <div className="row">
                    <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>שם: </label>
                          <span>מאיה אלט</span>
                        </li>
                        <li>
                          <label>תאריך לידה: </label>
                          <span>25 מאי 1996</span>
                        </li>
                        <li>
                          <label>גיל: </label>
                          <span>25 שנים</span>
                        </li>
                        <li>
                          <label>כתובת: </label>
                          <span>תל אביב</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>טלפון: </label>
                          <span dir="rtl"><a href="tel:+972542003332">+97254-2003332</a></span>
                        </li>
                        <li>
                          <label>אימייל: </label>
                          <span><a href="mailto:maya@alt-delight.co.il">maya@alt-delight.co.il</a></span>
                        </li>
                        <li>
                          <label>אינסטגרם: </label>
                          <span>Maya.Alt</span>
                        </li>
                        <li>
                          <label>עבודות מזדמנות: </label>
                          <span>פנויה</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End col */}
          </div>

          {/* separated */}

          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>

          {/* End separated */}
          <div className="title">
            <h3>היכולות שלי</h3>
          </div>

          <ServicesRTL />

          {/* End .row */}

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>תעודות</h3>
          </div>

          <Awards />
          {/* End Awards */}

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>המלצות</h3>
          </div>

          <Testimonials />
          {/* End Testimonaial */}
        </div>
      </section>
    </>
  );
};

export default AboutRTL;
