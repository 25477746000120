import React from "react";
import HeaderCookit from "../../components/header/HeaderCookit.jsx";
import Contact from "../../components/contact/Contact.jsx";
import ContactInfo from "../../components/contact/ContactInfo.jsx";
import Footer from "../../components/footer/FooterAnimation.jsx";
import useDocumentTitle from "../../components/useDocumentTitle.js";


const Pawfact = () => {
    useDocumentTitle("Pawfact project design by maya alt UX/UI");
    return (
        <div className="main-left">
            <HeaderCookit/> {/* End Header Section */}
            <section id="projects" className="b-white theme-light">
                <div>
                    <div className="blog-img">
                        <img className="W-100p" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660206120/Maya-web/pawfact-background.webp" alt="blog post"></img>
                    </div>
                    {/* End blog-img */}
                    <article className="article">
                        <div className="article-title">
                            <div className="px-md-5 px-3 media mx-auto w-xl-50">
                                <div className="avatar">
                                    <img src="/favicon-96x96.png" alt="thumbnail"/>
                                </div>
                                <div className="media-body mx-auto w-xl-50">
                                    <label>BY Maya Alt</label>
                                    <span>02 APR 2022</span>
                                </div>
                            </div>
                            <h1 className="pt-5p text-center px-md-5 px-3 mx-auto w-xl-50">
                                Pawfect is a health and location tracker app
                            </h1>
                            <p className="pt-2p f-para px-md-5 px-3 mx-auto w-xl-50">
                                designed to help owners take care of their pets by monitoring their vital signs, location, and activity.
                            </p>
                            <p className="f-para px-md-5 px-3 mx-auto w-xl-50">
                                With Pawfact smart collar, we can send accurate data to the veterinary system.
                            </p>
                            <p className="f-para px-md-5 px-3 mx-auto w-xl-50">
                                The veterinarian can see the data in a system designed for everyday tasks and pet monitoring.
                            </p>
                        </div>
                        {/* End .article-title */}
                        <div className="text-center pt-3 pb-3 mt-5 mb-5 px-md-5 px-3 bg-purple-25">
                            <small className="paw-prob">The problem</small>
                            <h2 className="f-title px-0 py-3 h3-st m-0">Owners of pets are unaware of their health in their everyday life, which happens for a few reasons</h2>
                        </div>
                        <div className="article-content">
                            <div className="row">
                                <div className="m-auto col-xl-5 order-2 order-xl-1">
                                    <h3 className="f-title pr-2p px-md-5 px-3">They can't tell us when something is wrong and tend to hide it</h3>
                                    <p className="f-para pr-2p px-md-5 px-3">Dogs have a higher tolerance for pain than humans, and some dogs will try to hide their pain as a natural survival instinct.</p>
                                </div>
                                <div className="mx-auto text-center mb-5p col-xl-7 order-1 order-xl-2">
                                    <img className="paw-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660176354/Maya-web/they_dont_speak_finel.gif" alt="they-dont-speak"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="text-center m-auto col-xl-7">
                                    <img className="paw-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660202913/Maya-web/too_much_space.gif" alt="they-dont-speak"/>
                                </div>
                                <div className="mx-auto col-xl-5">
                                    <h4 className="f-title pt-20p p-mobile-fix px-md-5 px-3">We can't be near our pets all the time.</h4>
                                    <p className="f-para pr-2p px-md-5 px-3">When not in our care, our curious pet can quickly eat harmful things, get lost, kidnapped, or even run over.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="m-auto col-xl-6 order-2 order-xl-1">
                                    <h4 className="pt-10p f-title px-md-5 px-3">We don't ask the right questions if nothing brings our attention to it</h4>
                                    <p className="f-para pr-2p px-md-5 px-3">Human beings, in our nature, respond to a problem often after they occur.
                                    </p>
                                    <p className="f-para pr-2p px-md-5 px-3">If we don't ask the right questions or are aware of the changes over time, there is a higher probability that the situation will be more serious when our pet requires our attention.
                                    </p>
                                    <p className="f-para pr-2p px-md-5 px-3">Therefore, we must know what's wrong and take care of the situation as soon as possible.</p>
                                </div>
                                <div className="pt-10p m-auto col-xl-6 order-1 order-xl-2">
                                    <img className="paw-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660208531/Maya-web/ask_right_qsemvy.gif" alt="products-design"/>
                                </div>
                            </div>
                            <div>
                                <p className="pt-10p f-title text-center mx-auto w-xl-50">On the other side</p>
                                <div className="text-center pt-3 pb-3 mt-5 mb-5 px-md-5 px-3 bg-purple-25">
                                    <small className="paw-prob">The Vet's problem</small>
                                    <h2 className="f-title px-0 py-3 h3-st m-0">The difference between the old and the new data is significant and therefore the treatment is not accurate</h2>
                                </div>
                                <div className="row">
                                    <div className="m-auto col-xl-5 order-2 order-xl-1">
                                        <h3 className="f-title pr-2p px-md-5 px-3">Nowadays, our local veterinarian sees our pet once every couple of months.</h3>
                                        <p className="f-para pr-2p px-md-5 px-3">By allowing our veterinarian to view new data at all times and notice every change in our pet's body, we can easily monitor our pet's vitals and provide better, faster, and lifesaving treatments.</p>
                                    </div>
                                    <div className="mx-auto text-center mb-5p col-xl-7 order-1 order-xl-2">
                                        <img className="paw-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660666589/Maya-web/dont_speak_vet_xzzgzq.svg" alt="they-dont-speak"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="text-center m-auto col-xl-7">
                                        <img className="paw-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660667256/Maya-web/vet_problem_right_answers_1_2_sqme3u.gif" alt="Pawfact-answers"/>
                                    </div>
                                    <div className="mx-auto col-xl-5">
                                        <h4 className="f-title pt-20p p-mobile-fix px-md-5 px-3">The veterinarian doesn't give treatments with enough data at hand.</h4>
                                        <p className="f-para pr-2p px-md-5 px-3">If he knew more, he could provide better and faster treatments.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="pt-10p m-auto col-xl-6 order-2 order-xl-1">
                                        <h4 className="f-title mx-auto px-md-5 px-3">More than one person usually takes care of the pet. Therefore, the Vet's information is incompatible</h4>
                                        <p className="f-para pr-2p m-auto px-md-5 px-3">Now the Vet needs to decide what is more likely to happen and, based on that, how to continue. The outcome is that the Vet wastes time when he could focus on providing better treatment.</p>
                                    </div>
                                    <div className="pt-10p m-auto col-xl-6 order-1 order-xl-2">
                                        <img className="paw-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660667347/Maya-web/doctor-vet_qann46.png" alt="doctor-questions"/>
                                    </div>
                                </div>

                                <h2 className="pt-10p f-size3r text-center ois">Implications</h2>
                                <p className="f-para w-xl-50 pr-2p m-auto px-md-5 px-3">
                                    As a result, we get to a point where our pet's health retrograde, and sometimes it is too late to treat it. Even if eventually it's treatable, it's harder to address it than it would have before. Also, don't forget that veterinarian care is costly, and not every family can bear the needed cost. Eventually, it puts our pets, who are part of our family, at risk.
                                </p>
                            </div>
                            <div>
                                <h3 className="f-title text-center mx-auto w-xl-50">The challenge
                                </h3>
                                <p className="f-para w-xl-50 pr-2p m-auto px-md-5 px-3">
                                    There are many challenges in this project, and here are the main ones:
                                </p>
                                <ul className="O-bull m-auto w-xl-50">
                                    <li>Alerts about your pet's health can be very stressful and scary. Therefore the notification should be calmly explicit with a call to action.</li>
                                    <li>Medical information can be very confusing, and Easley needs to see the critical information fast.</li>
                                    <li>Connecting the chip to the app and the pet collar easily</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="f-title text-center mx-auto w-xl-50">My Goal</h3>
                                <p className="f-para w-xl-50 pr-2p m-auto px-md-5 px-3">My goal is that the user would be more aware of the pet's health, so the goal is to see the user check at least once a week for information and changes in behavior and, if needed, contact the vet.</p>
                            </div>
                            <div>
                                <h3 className="f-title text-center mx-auto w-xl-50">Research</h3>
                                <p className="f-para w-xl-50 pr-2p m-auto px-md-5 px-3">I did some competitor research to see how other products tackled this issue and took notes of what they did, and analyzed them.</p>
                                <ul className="O-bull m-auto w-xl-50">
                                    <li>The onboarding needs to be simple and includes connecting the tracker on the pet collar easily by using steps and a visual display for how to do it.</li>
                                    <li>When the vital signs change-there should be a clear message if it's urgent or not and what to do now. It warns the user and reinforces a sense of control.</li>
                                    <li>Medical information could be tough to read and understand. the data needs to be simplified, divided into topics, and arranged by importance for the user</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="f-title text-center mx-auto w-xl-50">User Research</h3>
                                <p className="f-para pr-2p w-xl-50 m-auto px-md-5 px-3">The first thing I did was talk to a few veterinarians. They are very familiar with the owner's pain points. Asking them questions also helped me create an essential structure for my app questionnaire. Also, It was important to me to test different family structures to identify their various struggles with caring for a pet. Next, I conducted user interviews, wrote down all the responses, and began mapping them based on the topic.</p>
                            </div>
                            <div>
                                <h3 className="f-title text-center mx-auto w-xl-50">Main persona</h3>
                                <p className="f-para pr-2p w-xl-50 m-auto px-md-5 px-3 ">Next, I used all the data I gathered during the research process to create 'Alon'- my user persona.</p>
                            </div>
                            <div className="Bg-white100">
                                <img src="https://res.cloudinary.com/dw3voimry/image/upload/v1660205788/Maya-web/perosna.webp" alt="persona-design"/>
                            </div>
                            <div>
                                <h3 className="text-center f-title mx-auto w-s-80 w-xl-50">User research conclusions for the vet's system</h3>
                                <p className="f-para pr-2p w-xl-50 m-auto px-md-5 px-3">My user research helped me understand the vet's needs and how they affect the vet's system. In addition, it produced the following insights:</p>
                                <ul className="O-bull m-auto w-xl-50">
                                    <li>The vital signs need to compare to the pet's breed and other similar pets.</li>
                                    <li>Veterinarians work by three lists. Today's patients, follow-ups, and hospitalized patients. Every task needs to be simple and quick to add and read their medical history before treatment quickly.</li>
                                    <li>Not all medical history is essential. To make it simple and faster to read, it needs to show events by timeline and importance.</li>
                                </ul>
                            </div>
                            <div className="row">
                                <div>
                                    <h3 className="pt-5p text-center f-title mx-auto w-xl-50">Wireframe flows</h3>
                                    <p className="f-para w-xl-50 pr-2p m-auto px-md-5 px-3">I made the final result of the user flow after a couple of variations in Axure.
                                        I laid out all the pages to visualize how each piece related and how a user would access each section of the app and website.</p>
                                </div>
                                <img className="" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660206013/Maya-web/user-flow.webp" alt="user-flow-design"/>
                            </div>
                            <div className="row bg-welcome">
                                <div className="m-auto col-xl-5 order-2 order-xl-1">
                                    <h3 className="f-title pr-2p px-md-5 px-3">Splash screens, Designed to calm the user</h3>
                                    <p className="f-para pr-2p px-md-5 px-3">It was important to me that the goal of this app would be clear, engaging, and light. The health of our pets can be a very stressful matter. Therefore, I used colors and created illustrations to calm the user and make it less intimidating.</p>
                                </div>
                                <div className="mx-auto text-center mb-5p col-xl-7 order-1 order-xl-2">
                                    <img className="pt-20p screen-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660207121/Maya-web/welcome-phone.gif" alt="welcome-screen"/>
                                </div>
                            </div>
                            <div className="row bg-pink">
                                <div className="text-center m-auto col-xl-7">
                                    <img className="pt-20p pb-10p screen-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660523100/Maya-web/activity-screen-fix.gif" alt="they-dont-speak"/>
                                </div>
                                <div className="m-auto col-xl-5">
                                    <h4 className="f-title pt-20p p-mobile-fixed px-md-5 px-3">Achieving the right goals, Activity tracker, and relevant statistics</h4>
                                    <p className="f-para pr-2p px-md-5 px-3">Pawfact Activity tracker shows the overall health index and your pet's progress by the veterinarian's set of goals designed specifically for your pet. You can easily see when your pet was most active and if it achieved its goals over time.</p>
                                </div>
                            </div>
                            <div className="row bg-purple">
                                <div className="m-auto col-xl-5 order-2 order-xl-1">
                                    <h4 className="f-title px-md-5 px-3">Easily chat and schedule appointments</h4>
                                    <p className="f-para m-bot-fix pr-2p px-md-5 px-3">Chat with your veterinarian in case of an emergency consultant. You can schedule an appointment directly on the Profile Chat or the specific clinic page.</p>
                                </div>
                                <div className="text-center mx-auto col-xl-7 order-xl-2">
                                    <img className="pt-10p W-500 screen-image-fix-ltr" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660207245/Maya-web/chat-final-screen.gif" alt="products-design"/>
                                </div>
                            </div>
                            <div className="grad bg-fig order-1 ">
                                <h3 className="m-top-0 pt-10p w-s-80 text-center mx-auto w-xl-50">Pawfact-Vet follows the pet's medical history to analyze it over time</h3>
                                <img className="W-100" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660520832/Maya-web/Final-system.png" alt="patiant-screen"/>
                            </div>
                        </div>
                        {/* End article content */}
                        <ul className="nav tag-cloud">
                            <li>Web App</li>
                            <li>UI UX</li>
                            <li>Design</li>
                            <li>Web Design</li>
                            <li>Design thinking</li>
                            <li>Research</li>
                            <li>graphic design</li>
                        </ul>
                        {/* End tag */} </article>
                    {/* End Article */}
                    <div className="row bg-ma">
                        <div className="pl-15p mb-5p col-xl-4">
                            <div className="pt-10p">
                                <h3>Maya Alt</h3>
                                <small>UX designer</small>
                            </div>
                            <div className="mt-15p">
                                <span className="contact-1-en">
                                    <a className="mr-5p ml-5pr" href="#contactus">Contact</a>
                                </span>
                                <span className="resume-1-en">
                                    <a className="mr-5p ml-5pr" href="/resume.pdf">Resume</a>
                                </span>
                            </div>
                        </div>
                        <div className="pt-4p mpt-10 col-xl-8">
                            <h3 className="text-center pt-3 pb-1">See more of my work</h3>
                            <a href="/cookit">
                                <div className="my-bgn bg-w">
                                    <img src="https://res.cloudinary.com/dw3voimry/image/upload/v1660636048/Maya-web/cook-it-finished_fl2nve.webp" alt="pawect-post"/>
                                    <h4 className="Pawfect-h4">Cookit</h4>
                                </div>
                            </a>
                        </div>
                    </div>
                    {/* End .contact Form */} </div>
            </section>


            <section id="contactus" className="section theme-light dark-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-xl-4 m-15px-tb" data-aos="fade-right" data-aos-duration="1200">
                            <ContactInfo/>
                        </div>
                        {/* End Contact info */}

                        <div className="col-lg-7 ml-auto m-15px-tb" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                            <div className="contact-form">
                                <h4>Say Something</h4>
                                <Contact/>
                            </div>
                        </div>
                        {/* End contact form */}

                        {/* End Col */} </div>
                </div>
            </section>
            {/* End Contact Section */}

            <footer className="footer white">
                <div className="container">
                    <Footer/>
                </div>
            </footer>
            {/* End Contact Section */} </div>
    );
};

export default Pawfact;
