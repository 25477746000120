import React from "react";
import Header from "../../components/header/Header.jsx";
import Slider from "../../components/slider/SliderAnimation.jsx";
import About from "../../components/about/AboutAnimation.jsx";
import Contact from "../../components/contact/Contact.jsx";
import ContactInfo from "../../components/contact/ContactInfo.jsx";
import Footer from "../../components/footer/FooterAnimation.jsx";
import useDocumentTitle from "../../components/useDocumentTitle.js";

const HomeOne = () => {
    useDocumentTitle("Maya Alt | UX / UI and Web Designer");
    return (<div className="main-left theme-dark">
        <Header/> {/* End Header Section */}

        <Slider/> {/* End Slider Section */}

        <section id="projects" className="section">
            <div className="container">
                <div className="title">
                    <h3>My Projects.</h3>
                </div>
                <div class="row">
                    <div class="col-md-6 m-15px-tb aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200">
                        <div class="blog-grid">
                            <div class="blog-img">
                              <a href="/cookit"><img src="https://res.cloudinary.com/dw3voimry/image/upload/v1660636048/Maya-web/cook-it-finished_fl2nve.webp" alt="blog post"/></a>
                            </div>
                            <div class="blog-info">
                                <p class="meta">29/FEB/2022 - BY Maya Alt</p>
                                <p className="h4">Meal kit food delivery app and website</p>
                                <a href="/cookit">To the full project</a>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-6 m-15px-tb aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                            <div class="blog-grid">
                                <div class="blog-img">
                                    <a href="/pawfact"><img src="https://res.cloudinary.com/dw3voimry/image/upload/v1660206120/Maya-web/pawfact-background.webp" alt="pawect-post"/></a>
                                </div>
                                    <div class="blog-info">
                                        <div class="meta pt-1">02/April/2022- BY Maya Alt</div>
                                        <p className="h4">Pets health tracker and veterinarian system</p>
                                        <a href="/pawfact">To the full project</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Portfolio Section */}


                <About/> {/* End About Section */}


                <section id="contactus" className="section theme-light dark-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-xl-4 m-15px-tb" data-aos="fade-right" data-aos-duration="1200">
                                <ContactInfo/>
                            </div>
                            {/* End Contact info */}

                            <div className="col-lg-7 ml-auto m-15px-tb" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                                <div className="contact-form">
                                    <h4>Say Something</h4>
                                    <Contact/>
                                </div>
                            </div>
                            {/* End contact form */}

                            {/* End Col */} </div>
                    </div>
                </section>
                {/* End Contact Section */}

                <footer className="footer white">
                    <div className="container">
                        <Footer/>
                    </div>
                </footer>
                {/* End Contact Section */} </div>
            );
            };
            
            export default HomeOne;
