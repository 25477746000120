import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
function ContactFormHeb() {
  const [state, handleSubmit] = useForm("xgedgvnw");
  if (state.succeeded) {
      return <p className="Thanks">תודה רבה שיצרת איתי קשר! אשתדל לחזור אלייך בהקדם האפשרי</p>;
  }
  return (
    <>
      <form id="Ux-form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                id="name"
                name="name"
                type="text"
                className="form-control theme-light"
                placeholder="שם מלא"
              />
              <ValidationError 
                prefix="name" 
                field="name"
                errors={state.errors}
            />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                id="email"
                name="email"
                type="email"
                className="form-control theme-light"
                placeholder="כתובת אימייל"
                
              />
              <ValidationError 
                prefix="email" 
                field="email"
                errors={state.errors}
            />
            </div>
          </div>

          <div className="col-12">
            <div className="form-group mb-3">
              <input
                id="subject"
                name="subject"
                type="text"
                className="form-control theme-light"
                placeholder="נושא"
              />
              <ValidationError 
                prefix="subject" 
                field="subject"
                errors={state.errors}
            />
            </div>
          </div>

          <div className="col-12">
            <div className="form-group mb-3">
              <textarea
                id="comment"
                name="comment"
                rows="4"
                className="form-control theme-light"
                placeholder="כתוב הודעה"
              ></textarea>
              <ValidationError 
                prefix="comment" 
                field="comment"
                errors={state.errors}
            />
             
            </div>
          </div>

          <div className="col-12">
            <div className="btn-bar">
              <button type="submit" disabled={state.submitting} className="px-btn px-btn-white">שלח הודעה</button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
function ContactHeb() {
  return (
    <ContactFormHeb />
  );
}
export default ContactHeb;