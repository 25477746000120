import React from "react";
import {
  FaInstagram,
  FaLinkedinIn,
  FaPinterestP,
  FaBehance,
  FaDribbble,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaPinterestP />, link: "https://www.pinterest.com/altdelight" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/alt.delight" },
  { Social: <FaLinkedinIn />, link: "https://il.linkedin.com/in/maya-alt/" },
  { Social: <FaDribbble />, link: "https://dribbble.com/alt-delight" },
  { Social: <FaBehance />, link: "https://behance.net/alt-delight/" },
];

const Social = () => {
  return (
    <div className="nav social-icons justify-content-center">
      {SocialShare.map((val, i) => (
        <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
          {val.Social}
        </a>
      ))}
    </div>
  );
};

export default Social;
