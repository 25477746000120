import React from "react";
import HomeHeb from "../views/all/HomeHeb.jsx";
import Home from "../views/all/Home.jsx";
import NotFound from "../views/NotFound.jsx";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour.jsx";
import CookIt from "../views/all/cookit.jsx";
import Pawfact from "../views/all/pawfact.jsx";
import cookitHeb from "../views/all/cookitHeb.jsx";
import PawfactHeb from "../views/all/pawfactHeb.jsx";


const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/cookit" component={CookIt} />
          <Route exact path="/pawfact" component={Pawfact} />
          <Route exact path="/cookitheb" component={cookitHeb} />
          <Route exact path="/pawfactheb" component={PawfactHeb} />
          <Route exact path="/HE" component={HomeHeb} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
