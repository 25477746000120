import React from "react";
import HeaderCookit from "../../components/header/HeaderCookit.jsx";
import ContactHeb from "../../components/contact/ContactHeb.jsx";
import ContactInfoHeb from "../../components/contact/ContactInfoHeb.jsx";
import Footer from "../../components/footer/FooterAnimation.jsx";
import useDocumentTitle from "../../components/useDocumentTitle.js";


const Pawfact = () => {
    useDocumentTitle("Pawfact project design by maya alt UX/UI");
    return (
        <div dir="rtl" className="main-left">
            <HeaderCookit/> {/* End Header Section */}
            <section id="projects" className="b-white theme-light">
                <div>
                    <div className="blog-img">
                        <img className="W-100p" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660206120/Maya-web/pawfact-background.webp" alt="blog post"></img>
                    </div>
                    {/* End blog-img */}
                    <article className="article">
                        <div className="article-title">
                            <div className="px-md-5 px-3 media mx-auto w-xl-50">
                                <div className="avatar">
                                    <img src="/favicon-96x96.png" alt="תמונה ממוזערת"/>
                                </div>
                                <div className="heb media-body mx-auto">
                                    <label>מאת Maya Alt</label>
                                    <span>
                                        02 באפריל 2022</span>
                                </div>
                            </div>
                            <h1 className="pt-5p text-center px-md-5 px-3 mx-auto w-xl-50">
                                Pawfect היא אפליקציית בריאות לחיות מחמד ומעקב אחר מיקומם
                            </h1>
                            <p className="pt-2p f-para px-md-5 px-3 mx-auto w-xl-50">
                                Pawfect נועדה לעזור לבעלים לטפל בחיות המחמד שלהם על ידי ניטור הסממנים החיוניים, המיקום ופעילותם.
                            </p>
                            <p className="f-para px-md-5 px-3 mx-auto w-xl-50">
                                עם הקולר החכם של Pawfact, נוכל לשלוח נתונים מדויקים למערכת הווטרינרית.
                            </p>
                            <p className="f-para px-md-5 px-3 mx-auto w-xl-50">
                                הווטרינר יכול לראות את הנתונים במערכת המיועדת למשימות יומיומיות ולניטור חיות המחמד.
                            </p>
                        </div>
                        {/* End .article-title */}
                        <div className="text-center pt-3 pb-3 mt-5 mb-5 px-md-5 px-3 bg-purple-25">
                            <small className="paw-prob">הבעיה</small>
                            <h2 className="f-title px-0 py-3 h3-st m-0">בעלים של חיות מחמד אינם מודעים לבריאותם בחיי היומיום שלהם, מה שקורה מכמה סיבות</h2>
                        </div>
                        <div className="article-content">
                            <div className="row">
                                <div className="m-auto col-xl-5 order-2 order-xl-1">
                                    <h3 className="f-title pr-2p px-md-5 px-3">הם לא יכולים להגיד לנו מתי משהו לא בסדר ונוטים להסתיר זאת</h3>
                                    <p className="f-para pr-2p px-md-5 px-3">לכלבים יש סובלנות גבוהה יותר לכאב מאשר לבני אדם, ויש כלבים שינסו להסתיר את הכאב שלהם כאינסטינקט הישרדותי טבעי.</p>
                                </div>
                                <div className="mx-auto text-center mb-5p col-xl-7 order-1 order-xl-2">
                                    <img className="paw-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660176354/Maya-web/they_dont_speak_finel.gif" alt="they-dont-speak"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="text-center m-auto col-xl-7">
                                    <img className="paw-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660202913/Maya-web/too_much_space.gif" alt="they-dont-speak"/>
                                </div>
                                <div className="mx-auto col-xl-5">
                                    <h4 className="f-title pt-20p p-mobile-fix px-md-5 px-3">אנחנו לא יכולים להיות ליד חיות המחמד שלנו כל הזמן.</h4>
                                    <p className="f-para pr-2p px-md-5 px-3">כשאינן בטיפולנו, חיית המחמד הסקרנית שלנו יכולה לאכול במהירות דברים מזיקים, ללכת לאיבוד, להיחטף או אפילו להידרס.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="pt-10p m-auto col-xl-6 order-2 order-xl-1">
                                    <h4 className="f-title px-md-5 px-3">אנחנו לא שואלים את השאלות הנכונות אם שום דבר לא מסב את תשומת ליבנו לכך</h4>
                                    <p className="f-para pr-2p px-md-5 px-3">בני אדם, בטבע שלנו, מגיבים לבעיה לעתים קרובות לאחר שהתרחשה.
                                    </p>
                                    <p className="f-para pr-2p px-md-5 px-3">אם לא נשאל את השאלות הנכונות או מודעים לשינויים לאורך זמן, ישנה סבירות גבוהה יותר שהמצב יהיה חמור יותר כאשר חיית המחמד שלנו דורשת את תשומת הלב שלנו.</p>
                                    <p className="f-para pr-2p px-md-5 px-3">לכן עלינו לדעת מה לא בסדר ולטפל במצב בהקדם האפשרי.</p>
                                </div>
                                <div className="m-auto col-xl-6 order-1 order-xl-2">
                                    <img className="pt-10p paw-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660208531/Maya-web/ask_right_qsemvy.gif" alt="products-design"/>
                                </div>
                            </div>
                            <div>
                                <p className="pt-10p f-title text-center mx-auto w-xl-50">בצד השני</p>
                                <div className="text-center pt-3 pb-3 mt-5 mb-5 px-md-5 px-3 bg-purple-25">
                                    <small className="paw-prob">מה הבעיה של הוטרינר?</small>
                                    <h2 className="f-title px-0 py-3 h3-st m-0">ההבדל בין הנתונים הישנים לחדשים הוא משמעותי ולכן הטיפול אינו מדוייק</h2>
                                </div>
                                <div className="row">
                                    <div className="m-auto col-xl-5 order-2 order-xl-1">
                                        <h3 className="f-title pr-2p px-md-5 px-3">כיום, הווטרינר המקומי שלנו רואה את חיית המחמד שלנו אחת למספר חודשים.</h3>
                                        <p className="f-para pr-2p px-md-5 px-3">על ידי מתן אפשרות לווטרינר שלנו לצפות בנתונים חדשים בכל עת ולהבחין בכל שינוי בגוף חיית המחמד שלנו, אנו יכולים לנטר בקלות את המדדים של חיית המחמד שלנו ולספק טיפולים טובים יותר, מהירים יותר ומצילי חיים.</p>
                                    </div>
                                    <div className="mx-auto text-center mb-5p col-xl-7 order-1 order-xl-2">
                                        <img className="paw-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660666589/Maya-web/dont_speak_vet_xzzgzq.svg" alt="they-dont-speak"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="text-center m-auto col-xl-7">
                                        <img className="paw-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660667256/Maya-web/vet_problem_right_answers_1_2_sqme3u.gif" alt="Pawfact-answers"/>
                                    </div>
                                    <div className="mx-auto col-xl-5">
                                        <h4 className="f-title pt-20p p-mobile-fix px-md-5 px-3">הווטרינר לא נותן טיפולים עם מספיק נתונים בהישג יד.</h4>
                                        <p className="f-para pr-2p px-md-5 px-3">אם הוא ידע יותר, הוא היה יכול לספק טיפולים טובים ומהירים יותר.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="pt-10p m-auto col-xl-6 order-2 order-xl-1">
                                        <h4 className="f-title mx-auto px-md-5 px-3">יותר מאדם אחד מטפל בדרך כלל בחיית המחמד. לכן, המידע של הוטרינר אינו תואם</h4>
                                        <p className="f-para pr-2p m-auto px-md-5 px-3">כעת הווטרינר צריך להחליט מה סביר יותר שיקרה ועל סמך זה איך להמשיך. התוצאה היא שהווטרינר מבזבז זמן כשהוא יכול להתמקד במתן טיפול טוב יותר.</p>
                                    </div>
                                    <div className="pt-10p m-auto col-xl-6 order-1 order-xl-2">
                                        <img className="paw-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660667347/Maya-web/doctor-vet_qann46.png" alt="doctor-questions"/>
                                    </div>
                                </div>

                                <h2 className="pt-10p f-size3r text-center ois">השלכות</h2>
                                <p className="f-para pr-2p w-xl-50 m-auto px-md-5 px-3">
                                    כבעלי חיות מחמד, אנו בדרך כלל עוסקים בכמה נושאים עיקריים הנוגעים לחיות המחמד שלנו.
                                    אין לנו מידע מהימן ומדויק בגלל שלא נותנים מספיק תשומת לב למדדי חיית המחמד שלנו.
                                    לכן, כאשר מתרחשת בעיה, היא תהיה, ברוב המקרים, יקרה וקשה יותר לטיפול.
                                </p>
                            </div>
                            <div>
                                <h3 className="f-title text-center mx-auto w-xl-50">האתגר
                                </h3>
                                <p className="f-para pr-2p w-xl-50 m-auto px-md-5 px-3">
                                    יש הרבה אתגרים בפרויקט הזה, והנה העיקריים שבהם:
                                </p>
                                <ul className="H-bull m-auto w-xl-50">
                                    <li>התראות לגבי בריאות חיית המחמד שלך יכולות להיות מאוד מלחיצות ומפחידות. לכן ההודעה צריכה להיות מפורשת בשלווה עם קריאה לפעולה.</li>
                                    <li>מידע רפואי יכול להיות מאוד מבלבל, ויזואלית צריך לראות את המידע הקריטי במהירות.</li>
                                    <li>חיבור השבב לאפליקציה ולקולר חיית המחמד בקלות.</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="f-title text-center mx-auto w-xl-50">המטרה שלי</h3>
                                <p className="f-para pr-2p m-auto w-xl-50 px-md-5 px-3">על ידי מתן תשומת לב לחיות המחמד שלנו בכל עת, אנו יכולים בקלות לנטר, למנוע ולטפל בבעיה לפני שהיא מתפתחת למצב מסכן חיים.
                                    המטרה העיקרית היא קודם כל להעלות את המודעות של המשתמש לבריאות חיית המחמד שלו.
                                    אנו יכולים לראות את השינוי ההתנהגותי של המשתמש על ידי ניתוח השגרה שלו ושימו לב אם הוא משתמש במידע מדי שבוע או יוצר קשר עם הוטרינר ובאיזה הזדמנות.</p>
                            </div>
                            <div>
                                <h3 className="f-title text-center mx-auto w-xl-50">מחקר</h3>
                                <p className="f-para pr-2p m-auto w-xl-50 px-md-5 px-3">המחקר עבור פרויקט Pawfacts היה עצום ונמשך כשלושה חודשים.</p>
                                <p className="f-para pr-2p m-auto w-xl-50 px-md-5 px-3">הניתוח התחרותי שלי הניב את התובנות הבאות:</p>
                                <ul className="H-bull m-auto w-xl-50">
                                    <li>השימוש צריך להיות פשוט וכולל ענידת הקולר החכם על חיית המחמד בקלות על ידי שימוש בצעדים ותצוגה ויזואלית כיצד לעשות זאת.</li>
                                    <li>כאשר הסימנים החיוניים משתנים - צריך להיות מסר ברור אם זה דחוף או לא ומה לעשות עכשיו. זה מזהיר את המשתמש ומחזק את תחושת השליטה.</li>
                                    <li>מידע רפואי יכול להיות קשה לקריאה והבנה. יש לפשט את הנתונים, לחלק אותם לנושאים ולסדר אותם לפי חשיבות עבור המשתמש.</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="f-title text-center mx-auto w-xl-50">מחקר משתמשים</h3>
                                <p className="f-para pr-2p m-auto w-xl-50 px-md-5 px-3">מחקר המשתמשים שלי מסתמך על מספר מרכיבים:</p>
                                <ul className="H-bull m-auto w-xl-50">
                                    <li>הסימנים החיוניים צריכים להשוות לגזע חיית המחמד וחיות מחמד דומות אחרות.</li>
                                    <li>וטרינרים עובדים לפי שלוש רשימות. המטופלים, המעקבים והמטופלים של היום. כל משימה צריכה להיות פשוטה ומהירה כדי להוסיף ולקרוא את ההיסטוריה הרפואית שלה לפני הטיפול במהירות.</li>
                                    <li>לא כל ההיסטוריה הרפואית חיונית. כדי להפוך אותו לפשוט ומהיר יותר לקריאה, הוא צריך להציג אירועים לפי ציר זמן וחשיבות.</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="f-title text-center mx-auto w-xl-50">פרסונה ראשית</h3>
                                <p className="f-para pr-2p m-auto w-xl-50 px-md-5 px-3 ">השתמשתי בכל הנתונים שאספתי במהלך תהליך המחקר וצמצמתי אותם כדי ליצור את 'אלון'- דמות המשתמש שלי.</p>
                            </div>
                            <div className="Bg-white100">
                                <img src="https://res.cloudinary.com/dw3voimry/image/upload/v1660205788/Maya-web/perosna.webp" alt="persona-design"/>
                            </div>
                            <div>
                                <h3 className="text-center f-title mx-auto w-xl-50">מסקנות מחקר משתמשים עבור המערכת של הוטרינר</h3>
                                <p className="f-para pr-2p m-auto w-xl-50 px-md-5 px-3">מחקר המשתמש שלי עזר לי להבין את הצרכים של הוטרינר וכיצד הם משפיעים על המערכת של הוטרינר.</p>
                                <p className="f-para pr-2p m-auto w-xl-50 px-md-5 px-3">המחקר הניב את התובנות הבאות:</p>
                                <ul className="H-bull m-auto w-xl-50">
                                    <li>הסימנים החיוניים צריכים להשוות לגזע חיית המחמד וחיות מחמד דומות אחרות.</li>
                                    <li>וטרינרים עובדים לפי שלוש רשימות:
                                        החולים של היום,
                                        מעקבים
                                        חולים מאושפזים.
                                        כל משימה צריכה להיות פשוטה ומהירה כדי להוסיף ולקרוא את ההיסטוריה הרפואית שלה לפני הטיפול במהירות.</li>
                                    <li>לא כל היסטוריה רפואית חיונית. כדי להפוך אותה לפשוטה ומהירה יותר לקריאה, היא צריכה להראות אירועים לפי ציר זמן וחשיבות.</li>
                                </ul>
                            </div>
                            <div className="row">
                                <div>
                                    <h3 className="pt-5p text-center f-title mx-auto w-xl-50">Wireframe</h3>
                                    <p className="f-para pr-2p w-xl-50 m-auto px-md-5 px-3">תכננתי את בניית העמודים כדי להעריך איך כל החלקים מתחברים וכיצד המשתמשים היו ניגשים לכל אזור של האפליקציה והאתר.</p>
                                </div>
                                <img className="" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660206013/Maya-web/user-flow.webp" alt="user-flow-design"/>
                            </div>
                            <div className="row bg-welcome">
                                <div className="m-auto col-xl-5 order-2 order-xl-1">
                                    <h3 className="f-title pr-2p px-md-5 px-3">מסכי פתיחה, מיועדים להרגיע את המשתמש</h3>
                                    <p className="f-para pr-2p px-md-5 px-3">היה לי חשוב שהמטרה של האפליקציה הזו תהיה ברורה, מושכת וקלילה.
                                        הבריאות של חיות המחמד שלנו יכולה להיות עניין מלחיץ מאוד. לכן השתמשתי בצבעים ויצרתי איורים כדי להרגיע את המשתמש ולהפוך אותו לפחות מאיים.</p>
                                </div>
                                <div className="mx-auto text-center mb-5p col-xl-7 order-1 order-xl-2">
                                    <img className="pt-20p screen-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660207121/Maya-web/welcome-phone.gif" alt="welcome-screen"/>
                                </div>
                            </div>
                            <div className="row bg-pink">
                                <div className="text-center m-auto col-xl-7">
                                    <img className="pt-20p pb-10p screen-image" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660523100/Maya-web/activity-screen-fix.gif" alt="they-dont-speak"/>
                                </div>
                                <div className="m-auto col-xl-5">
                                    <h4 className="f-title pt-20p p-mobile-fixed px-md-5 px-3">השגת היעדים הנכונים, מעקב אחר פעילות וסטטיסטיקה רלוונטית</h4>
                                    <p className="p-fix f-para pr-2p px-md-5 px-3">מעקב הפעילות של Pawfact מציג את מדד הבריאות הכללי ואת ההתקדמות של חיית המחמד שלך לפי קבוצת המטרות של הווטרינר שתוכננה במיוחד עבור חיית המחמד שלך.
                                        אתה יכול לראות בקלות מתי חיית המחמד שלך הייתה הכי פעילה ואם היא השיגה את מטרותיה לאורך זמן.</p>
                                </div>
                            </div>
                            <div className="row bg-purple">
                                <div className="m-auto col-xl-5 order-2 order-xl-1">
                                    <h4 className="p-m-fix f-title px-md-5 px-3">שוחח בקלות וקבע פגישות</h4>
                                    <p className="f-para m-bot-fix pr-2p px-md-5 px-3">התייעץ עם הווטרינר שלך במקרה חירום.
                                        ניתן לקבוע פגישה ישירות בצ'אט בפרופיל או בעמוד הקליניקה הספציפי.
                                    </p>
                                </div>
                                <div className="text-center mx-auto col-xl-7 order-1 order-xl-2">
                                    <img className="pt-10p W-500 screen-image-fix" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660207245/Maya-web/chat-final-screen.gif" alt="products-design"/>
                                </div>
                            </div>
                            <div className="grad bg-fig">
                                <h3 className="m-top-0 pt-10p my-title text-center mx-auto w-xl-50">Pawfact-Vet עוקבת אחר ההיסטוריה הרפואית של חיית המחמד כדי לנתח אותה לאורך זמן.</h3>
                                <img className="W-100" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660520832/Maya-web/Final-system.png" alt="patiant-screen"/>
                            </div>
                        </div>
                        {/* End article content */}
                        <ul className="nav tag-cloud">
                            <li>Web App</li>
                            <li>UI UX</li>
                            <li>Design</li>
                            <li>Web Design</li>
                            <li>Design thinking</li>
                            <li>Research</li>
                            <li>graphic design</li>
                        </ul>
                        {/* End tag */} </article>
                    {/* End Article */}
                    <div className="row bg-ma">
                        <div className="pr-10p mb-5p col-xl-4">
                            <div className="pt-10p">
                                <h3>מאיה אלט</h3>
                                <small>מעצבת UX/UI</small>
                            </div>
                            <div className="mt-15p">
                                <span className="contact-1">
                                    <a className="mr-5p ml-5pr" href="#contactus">צור קשר</a>
                                </span>
                                <span className="resume-1">
                                    <a className="mr-5p ml-5pr" href="/resume.pdf">קורות החיים שלי</a>
                                </span>
                            </div>
                        </div>
                        <div className="pt-4p mpt-10 col-xl-8">
                            <h3 className="text-center pt-3 pb-1">עוד מהעבודות שלי</h3>
                            <a href="/cookitHeb">
                                <div className="m-auto my-bgn bg-w">
                                    <img src="https://res.cloudinary.com/dw3voimry/image/upload/v1660636048/Maya-web/cook-it-finished_fl2nve.webp" alt="pawect-post"/>
                                    <h4 className="Pawfect-h4">Cookit</h4>
                                </div>
                            </a>
                        </div>
                    </div>
                    {/* End .contact Form */} </div>
            </section>


            <section id="contactus" className="section theme-light dark-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-xl-4 m-15px-tb" data-aos="fade-right" data-aos-duration="1200">
                            <ContactInfoHeb/>
                        </div>
                        {/* End Contact info */}

                        <div className="col-lg-7 ml-auto m-15px-tb" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                            <div className="contact-form">
                                <h4>צור קשר</h4>
                                <ContactHeb/>
                            </div>
                        </div>
                        {/* End contact form */}

                        {/* End Col */} </div>
                </div>
            </section>
            {/* End Contact Section */}

            <footer className="footer white">
                <div className="container">
                    <Footer/>
                </div>
            </footer>
            {/* End Contact Section */} </div>
    );
};

export default Pawfact;
