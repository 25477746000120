import React from "react";
import HeaderCookit from "../../components/header/HeaderCookit.jsx";
import Contact from "../../components/contact/Contact.jsx";
import ContactInfo from "../../components/contact/ContactInfo.jsx";
import Footer from "../../components/footer/FooterAnimation.jsx";
import useDocumentTitle from "../../components/useDocumentTitle.js";
import ReactPlayer from 'react-player/lazy'


const CookIt = () => {
    useDocumentTitle("Cookit project design by maya alt UX/UI");
    return (
        <div className="main-left">
            <HeaderCookit/> {/* End Header Section */}
            <section id="projects" className="b-white theme-light">
            <div>
                                    <div className="blog-img">
                                        <img className="W-100p" src="https://res.cloudinary.com/dw3voimry/image/upload/v1660636048/Maya-web/cook-it-finished_fl2nve.webp" alt="blog post"></img>
                                    </div>
                                    {/* End blog-img */}
                                    <article className="article">
                                        <div className="article-title">
                                            <h2 className="px-md-5 px-3 mx-auto w-xl-50">
                                                <b>Overview</b>
                                            </h2>
                                            <div className="px-md-5 px-3 media mx-auto w-xl-50">
                                                <div className="avatar">
                                                    <img src="/favicon-96x96.png" alt="thumbnail"/>
                                                </div>
                                                <div className="media-body mx-auto w-xl-50">
                                                    <label>BY Maya Alt</label>
                                                    <span>26 FEB 2022</span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End .article-title */}

                                        <div className="article-content">
                                            <p className="px-md-5 px-3 mx-auto w-xl-50">
                                                "Cookit" is a meal delivery app and website designed to help people meet their dietary needs while saving time by planning and offering the exact amount needed, so no food goes to waste. All this by offering a wide and various range of optional meals.
                                            </p>
                                            <p className="px-md-5 px-3  mb-25 mx-auto w-xl-50">
                                                In this story, I will provide insights into my thought process, the methods and tools I used, and explain my reasoning and thoughts behind the decisions I made.
                                            </p>
                                            <p className="px-md-5 px-3 mx-auto w-xl-50">
                                                <small className="mx-auto w-xl-50">
                                                    <b>My role: UX\UI design</b>
                                                </small>
                                            </p>
                                            <h2 className="px-md-5 px-4 pb-4 pt-4 mx-auto w-xl-50">
                                                <b>Get a Taste</b>
                                                
                                            </h2>
                                            <div className="pb-5">
                                                <ReactPlayer height="850px" width="100%" url='https://www.youtube.com/embed/5qrd5r2DcvE' autoplay="true" playing="true" loop="false" controls="true" config={{ file: { attributes: { controlsList: 'nodownload'}}}}/>
                                            </div>
                                            <h2 className="px-md-5 px-4 pb-4 pt-4 mx-auto w-xl-50">
                                                <b>Let's go back to the beginning</b> 
                                            </h2>
                                            <div className="row">
                                                <div>
                                                    <h3 className="px-md-5 px-3 mx-auto w-xl-50">My Design Process</h3>
                                                    
                                                    <p className="px-md-5 px-3  mx-auto w-xl-50">
                                                        Having a design process provides me with a framework on how to best tackle a problem by breaking it down into smaller steps.
                                                    </p>
                                                </div>
                                                <div className="Bg-white100 text-center ">
                                                    <img src="/img/blog/process.png" alt="process-design"/>
                                                </div>
                                            </div>

                                            <h3 className="px-md-5 px-3 mx-auto w-xl-50">The problem</h3>
                                            <p className="px-md-5 px-3 w-xl-50 mx-auto">
                                                The grocery shopping experience in Israel is not good enough. Nowadays, it's hard to maintain a regular healthy diet, and it is even more challenging if you have a specific diet or nutrition.
                                            </p>
                                            <p className="px-md-5 px-3 mx-auto w-xl-50 mb-5">
                                                The shopping centers/stores encourage us to buy more than we need, based on a comfortable logic for the store's profit and not on what the customer needs.
                                            </p>
                                            <div className="pt-3 pb-3 mt-5 mb-5 px-md-5 px-3 Bg-green25">
                                                <h3 className="px-0 py-3 h3-st m-0">Why is that?</h3>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-5 order-2 order-xl-1">
                                                    <h4 className="pr-2p px-md-5 px-3">Shopping online</h4>
                                                    <p className="pr-2p px-md-5 px-3">Shopping for groceries online is still a very time-consuming process. We still need to check every item on our list, even if you saved past orders that you made.</p>
                                                    <p className="pr-2p px-md-5 px-3">The stores try to up-sale specific ingredients to enlarge the purchase. The process causes the user to make more decisions, and the more decisions you might have, the more time-consuming it will be.</p>
                                                    <p className="pr-2p px-md-5 px-3">And eventually, after all that, we can still make mistakes and forget an ingredient, or we don't get it at all because it is out of stock. Either way, we are stuck without a crucial ingredient to our meal.</p>
                                                </div>
                                                <div className="pt-5 text-center mb-5p col-xl-7 order-1 order-xl-2">
                                                    <img className="" src="/img/blog/products.gif" alt="products-design"/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="text-center pt-5 col-xl-7">
                                                    <img className="food-wasted mobilept-4p" src="/img/blog/food_wasted.png" alt="Food-wasted"/>
                                                </div>
                                                <div className="col-xl-5">
                                                    <h4 className="pt-20p px-md-5 px-3">Groceries are bought by weight</h4>
                                                    <p className="pr-2p px-md-5 px-3">The groceries we buy are measured by weight and not by portions. It seems minor, but every member of a family eats a bit different, we buy the food for everyone, but we don't eat all of it because of various personal preferences or health reasons. As a result, we buy more than we need, spend more money and a lot of the food goes to waste. FAO released a study that shows a third of all food produced globally is lost or goes to waste, and in the mid-to-high-income countries, food is wasted and lost mainly at later stages in the retail and consumer levels.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-6 order-2 order-xl-1">
                                                    <h4 className="pt-10p pr-2p px-md-5 px-3 mt-18p">Our perception of food</h4>
                                                    <p className="pr-2p px-md-5 px-3">Did it ever happen to you that you go to the fridge, there's a couple of ingredients there, but it doesn't combine? Well... that's because they don't.</p>
                                                    <p className="pr-2p px-md-5 px-3">We think about ingredients instead of meals, and planning your meals takes time- if you want some variety, it takes even longer.</p>
                                                    <p className="pr-2p px-md-5 px-3">This is also a result of too much decisions to make- The whole process of thinking about meals and breaking them down to ingredients and then trying to rebuild them into a meal makes the process complicated and time-consuming.</p>
                                                </div>
                                                <div className="pt-10p text-center mb-5p col-xl-6 order-1 order-xl-2">
                                                    <img src="/img/blog/think-different.gif" alt="products-design"/>
                                                </div>
                                            </div>
                                            <div className="f-size3r text-center ois">Or in short</div>
                                            <div className="mb-5p px-md-5 px-3 Bg-green25">
                                                <p className="f-size2r f-italian text-center pt-2p ">The time it takes to make a decision increases with the number and complexity of choices.</p>
                                                <small className="text-center text-white ml-50p ">Hick's law</small>
                                            </div>
                                            <div>
                                                <h3 className="px-md-5 px-3 mx-auto w-xl-50">The Result
                                                </h3>
                                                <p className="px-md-5 px-3  mx-auto w-xl-50">
                                                    The customer bought more than he needed, wasted more money, and left with most of the leftovers that go to waste.
                                                </p>
                                            </div>
                                            <div>
                                                <h3 className="px-md-5 px-3 mx-auto w-xl-50">The Challenge</h3>
                                                <p className="px-md-5 px-3  mx-auto w-xl-50">
                                                    The main challenge comes from the fact that there is no product similar in Israel, and the idea is unfamiliar to Israeli users. Another challenge is to take the Swiss concept of meal-kit delivery that had a lot of success in the US and other European markets and modify it to the Israeli market.
                                                </p>
                                            </div>
                                            <div>
                                                <h3 className="px-md-5 px-3 mx-auto w-xl-50">My Goal</h3>
                                                <p className="px-md-5 px-3  mx-auto w-xl-50">My goal is for the user to complete the entire process- from buying the meals- to make them at home successfully.</p>
                                            </div>
                                            <div>
                                                <h3 className="px-md-5 px-3 mx-auto w-xl-50">Research</h3>
                                                <p className="px-md-5 px-3  mx-auto w-xl-50">I analyzed the competitors and similar apps in different industries for specific flows. I studied the strengths and weaknesses of each company.</p>
                                            </div>
                                            <div>
                                                <p className="px-md-5 px-3  mx-auto w-xl-50">
                                                    <b>My competitor's analysis produced the following insights:</b>
                                                </p>
                                                <ul className="px-md-5 px-3  mx-auto w-xl-50">
                                                    <li className="pb-2p ml-5p ">The app/site needs to show its value while highlighting the pain points of the primary persona. The understanding of how it works should be clear and straightforward.</li>
                                                    <li className="pb-2p ml-5p ">We can strengthen our credibility in many ways such as: showing videos of customers that tried the product, reviews, FAQs, who are we, and why we made this product, customer service, and compensation or refund in case of a problem to encourage the users to join the service.</li>
                                                    <li className="pb-2p ml-5p ">I will achieve my goal after a successful preparation of the whole meal- therefore, we need to make the entire experience as holistic as possible by making the ingredients interactive and simplifying the recipe. By breaking it down into steps with video instructions, automatic timers, and voice Activision due to lack of hand accessibility while cooking.</li>
                                                    <li className="pb-2p ml-5p ">Calls to action are required to create urgency for the users to start using the product. There are many options like a discount coupon for a limited time, a discount for the first few purchases, bringing a friend for credit, and more. Also, there should be at least one call to action to push the user in the right direction while we still have the user's attention.</li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h3 className="px-md-5 px-3  mx-auto w-xl-50">User Research</h3>
                                                <p className="px-md-5 px-3  mx-auto w-xl-50">To design an application and a website with a great user experience, the need of the targeted audience had to match. I had to identify the needs of the target audience. I conducted user interviews and surveys to find their main pain points and needs. It was important to ask different domestic structures to understand their perspective and to whom I can give the most value and focus on that persona. After completing the interviews, I wrote down all the responses and mapped them based on the topic.</p>
                                            </div>
                                            <div>
                                                <h3 className="px-md-5 px-3  mx-auto w-xl-50">Main persona</h3>
                                                <p className="px-md-5 px-3  mx-auto w-xl-50 ">Next, I used all of the data I gathered during the research process to create 'Ori'- my user persona. Ori is a software developer at a small company.</p>
                                            </div>
                                            <div className="Bg-white100">
                                                <img src="/img/blog/persona.png" alt="persona-design"/>
                                            </div>
                                            <div className="row">
                                                <div>
                                                    <h3 className="px-md-5 px-3  mx-auto w-xl-50">User Flow</h3>
                                                    <p className="px-md-5 px-3  mx-auto w-xl-50">To figure out how viable my solution would be. 
                                                                                                                                                                                                        Based on the user research and survey, I created a rough storyline. 
                                                                                                                                                                                                        As part of this user flow, I started mapping out the screens and how the user might discover the service.
                                                    </p>
                                                </div>
                                                <div className="Bg-white100">
                                                    <img className="pl-10p" src="/img/blog/user-flow.png" alt="user-flow-design"/>
                                                </div>
                                            </div>
                                            <div className="row">
                                            <div>
                                                <h3 className="px-md-5 px-3  mx-auto w-xl-50">Sketches</h3>
                                                <p className="px-md-5 px-3  mx-auto w-xl-50">After I understood the user flow in the project, I created low-fidelity sketches. That is how I iterate through many options quickly and try different ideas.</p>
                                            </div>
                                            <img className="" src="/img/blog/sketches.png" alt="user-flow-design"/>
                                            </div>
                                            <div className="row">
                                            <div>
                                                <h3 className="px-md-5 px-3  mx-auto w-xl-50">Different Navigation Ideas</h3>
                                                <p className="px-md-5 px-3  mx-auto w-xl-50 ">
                                                    I tried different navigation ideas for the mobile app, afterward, I began developing both products together for consistency.
                                                </p>
                                            </div>
                                            <div className="pt-5p Bg-white100">
                                                <img className="" src="/img/blog/diff-idea.png" alt="different-ideas-design"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div>
                                                <h3 className="px-md-5 px-3  mx-auto w-xl-50">Wireframe Flows</h3>
                                                <p className="px-md-5 px-3  mx-auto w-xl-50 ">This was the final result of the user flow after a couple of variations in Axure.</p>
                                                <p className="px-md-5 px-3  mx-auto w-xl-50 ">I laid out all of the pages so I could visualize how each piece related to one another and how a user would access each section of the app and website.</p>
                                            </div>
                                            <div className="pt-5p Bg-green25">
                                                <img className="m-width-95" src="/img/blog/proto.png" alt="prototype-design"/>
                                            </div>
                                            </div>
                                            <div className="row">
                                                <div className="px-md-5 px-3 mx-auto w-xl-50 col-xl-6 order-2 order-xl-1">
                                                    <h4 className="pt-10p px-md-5 px-3">Cooking Time</h4>
                                                    <p className="px-md-5 px-3">A quick reminder is that the goal is for the user to complete all processes starting from buying the meals to making them at home successfully. Therefore we need to think of other elements that could improve or damage the experience.</p>
                                                    <p className="px-md-5 px-3">To make the experience better through the cooking process, I added voice command to the recipe page for options like continue, start a timer, and more to make the experience whole.</p>
                                                </div>
                                                <div className="col-xl-6 text-left order-1 order-xl-2">
                                                    <img className="ml-18p-fix M-W60p" src="/img/blog/cooking-time.gif" alt="cooking-time-design"/>
                                                </div>
                                            </div>
                                            <div className="row pt-7p pb-5p">
                                                <div dir="rtl" className="text-right col-xl-6">
                                                    <img className="mr-18p-fix M-W60p" src="/img/blog/order-made-easy.gif" alt="oreder-made-easy-design"/>
                                                </div>
                                                <div className="col-xl-6">
                                                    <h4 className="pt-20p px-md-5 px-3">Re - order Made Easy</h4>
                                                    <p className="px-md-5 px-3">As I said before, for the user to order easily and quickly, there should be limited options, but in the user research, I found that flexibility is crucial for events, weekends and in case they just want something different.</p>
                                                    <p className="px-md-5 px-3">Re-orders will be created automatically according to the user's preferences and dietary needs (using filters).</p>
                                                    <p className="px-md-5 px-3">It could be edited simply by using the app\site, if you expect company or want to try something new.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End article content */}
                                        <ul className="nav tag-cloud">
                                            <li>Web App</li>
                                            <li>UI UX</li>
                                            <li>Design</li>
                                            <li>Web Design</li>
                                            <li>Design thinking</li>
                                            <li>Research</li>
                                            <li>graphic design</li>
                                        </ul>
                                        {/* End tag */} </article>
                                    {/* End Article */}
                                    <div className="row bg-ma">
                                        <div className="pl-15p mb-5p col-xl-4">
                                            <div className="pt-10p">
                                                <h3>Maya Alt</h3>
                                                <small>UX designer</small>
                                            </div>
                                            <div className="mt-15p">
                                                <span className="contact-1-en">
                                                    <a className="mr-5p ml-5pr" href="#contactus">Contact</a>
                                                </span>
                                                <span className="resume-1-en">
                                                    <a className="mr-5p ml-5pr" href="/resume.pdf">Resume</a>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="pt-4p mpt-10 col-xl-8">
                                            <h3 className="text-center pt-3 pb-1">See more of my work</h3>
                                            <a href="/pawfact">
                                            <div className="my-bgn bg-w">
                                                <img src="https://res.cloudinary.com/dw3voimry/image/upload/v1660206120/Maya-web/pawfact-background.webp" alt="pawect-post"/>
                                                <h4 className="Pawfect-h4">PAWFECT</h4>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    {/* End .contact Form */} </div>
            </section>


            <section id="contactus" className="section theme-light dark-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-xl-4 m-15px-tb" data-aos="fade-right" data-aos-duration="1200">
                            <ContactInfo/>
                        </div>
                        {/* End Contact info */}

                        <div className="col-lg-7 ml-auto m-15px-tb" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                            <div className="contact-form">
                                <h4>Say Something</h4>
                                <Contact/>
                            </div>
                        </div>
                        {/* End contact form */}

                        {/* End Col */} </div>
                </div>
            </section>
            {/* End Contact Section */}

            <footer className="footer white">
                <div className="container">
                    <Footer/>
                </div>
            </footer>
            {/* End Contact Section */} </div>
    );
};

export default CookIt;
