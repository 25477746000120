import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
function ContactForm() {
  const [state, handleSubmit] = useForm("xgedgvnw");
  if (state.succeeded) {
      return <p id="Thanks">Thanks for reaching out! I'll get back to you as soon as possible</p>;
  }
  return (
    <>
      <form id="Ux-form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                id="name"
                name="name"
                type="text"
                className="form-control theme-light"
                placeholder="Full name"
              />
              <ValidationError 
                prefix="name" 
                field="name"
                errors={state.errors}
            />
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                id="email"
                name="email"
                type="email"
                className="form-control theme-light"
                placeholder="Email address"
                
              />
              <ValidationError 
                prefix="email" 
                field="email"
                errors={state.errors}
            />
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group mb-3">
              <input
                id="subject"
                name="subject"
                type="text"
                className="form-control theme-light"
                placeholder="Subject"
              />
              <ValidationError 
                prefix="subject" 
                field="subject"
                errors={state.errors}
            />
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="form-group mb-3">
              <textarea
                id="comment"
                name="comment"
                rows="4"
                className="form-control theme-light"
                placeholder="Type comment"
              ></textarea>
              <ValidationError 
                prefix="comment" 
                field="comment"
                errors={state.errors}
            />
             
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="btn-bar">
              <button type="submit" disabled={state.submitting} className="px-btn px-btn-white">Send Message</button>
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
}
function Contact() {
  return (
    <ContactForm />
  );
}
export default Contact;