import React from "react";

const ServiceContent = [
  {
    icon: "icon-mobile",
    title: "איפיון UX",
    descriptions: `בסופו של דבר, גם אם המוצר מדהים וחווית המשתמש שלו לא מספקת, הלקוחות לא ישתמשו בו. אני רואה את חווית המשתמש כאמנות פרוסה לנגד עיני. המרחביות שלי היא הצבת החלקים בצורה המתאימה והנכונה על מנת שישתלבו במוצר אחד שאין שני לו. ביצוע מחקרי שוק, קהל יעד ועמידה בסטנדרטים המחמירים ביותר מאפשרים לי לספק את המוצר המושלם לכל פרויקט.`,
    delayAnimation: "200",
  },
  {
    icon: "icon-desktop",
    title: "עיצוב אתרי אינטרנט",
    descriptions: `
    עיצוב אתרים הפך כבר מזמן ליותר מסתם, עמוד אפיון ונגישות לקהל היעד. על מנת שהאתר יהיה רלוונטי, עלינו לעצב אותו כך שיתאים למכשירים ניידים בתור התצוגה העיקרית. לכן עלינו לבצע את המחקר בהתאם, להעריך את קהל היעד כדי להבין את מטרות הפרויקט, ולהתמקד בחוויית המשתמש באתר בנוסף לעיצובו החדשני.
    המשתמשים שלנו יעריכו תשומת לב לפרטים הקטנים ואנו נתוגמל על כך בהתאם.`,
    delayAnimation: "",
  },
  {
    icon: "icon-target",
    title: "מחקר משתמשים",
    descriptions: `חווית משתמש אינה שפה טבעית.
    לקהל שלך יש תרגום לאותה מתודולוגיה. מחקר משתמשים עוסק ביצירת הפרסונה המתאימה למוצר שלך ויצירת המטרה הסופית שלך בהתאם.
    המחקר שלי רחב וכולל סקרים מקוונים, ראיונות גלובליים, פלטפורמות חיפוש, מאמרים ייעודיים, כלי ניתוח מתקדמים, אתרי צד שלישי ועוד הרבה יותר.
    לאחר איסוף כל החלקים, אני יכול להתחיל ליצור את החוויה של הקהל שלך.`,
    delayAnimation: "400",
  }
];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className="col-md-6 col-lg-4 my-3"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-01">
              <div className="icon">
                <i className={`icon ${val.icon}`}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
