import React from "react";
import {
    FaInstagram,
    FaLinkedinIn,
    FaPinterestP,
    FaBehance,
    FaDribbble,
  } from "react-icons/fa";
  
  const SocialShare = [
    { Social: <FaPinterestP />, link: "https://www.pinterest.com/altdelight" },
    { Social: <FaInstagram />, link: "https://www.instagram.com/alt.delight" },
    { Social: <FaLinkedinIn />, link: "https://il.linkedin.com/in/maya-alt/" },
    { Social: <FaDribbble />, link: "https://dribbble.com/alt-delight" },
    { Social: <FaBehance />, link: "https://behance.net/alt-delight/" },
  ];

const Footer = () => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-6 my-2">
          <div className="nav justify-content-center justify-content-md-start">
            {SocialShare.map((val, i) => (
              <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
                {val.Social}
              </a>
            ))}
          </div>
          {/* End .nav */}
        </div>
        {/* End .col */}

        <div className="col-md-6 my-2 text-center text-md-end">
          <p>
            © {new Date().getFullYear()} copyright{" "}
            <a
              href="https://seo-top.co.il/"
              target="_blank"
              rel="noreferrer"
            >
              Seo Top
            </a>{" "}
            כל הזכויות שמורות
          </p>
        </div>
        {/* End .col */}
      </div>
      {/* End .row */}
    </>
  );
};

export default Footer;
