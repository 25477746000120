import React, { useEffect } from "react";
import Routes from "./router/Routes.js";
import ScrollToTop from "./components/ScrollToTop.jsx";
import AnimatedCursor from "react-animated-cursor";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import { FormspreeProvider } from '@formspree/react';


const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Helmet>‍
          <title>Maya Alt</title>‍
      </Helmet>
      <FormspreeProvider project="Alt-delight">
      </FormspreeProvider>
      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="47, 156, 149"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.4}
      />
      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;
