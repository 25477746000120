import React from "react";

const ContactInfoHeb = () => {
  return (
    <>
      <div className="contact-info">
        <h4>מה הסיפור שלך?</h4>
        <p>
        תמיד זמינה לפרוייקטים כפרילנסרית אם יש לך פרוייקט שאני יכולה לתרום לו ,
          תרגיש חופשי ליצור איתי קשר.
        </p>

        <ul>
          <li className="media">
            <i className="icon icon-map"></i>
            <span className="media-body text-white">
              תל אביב. ישראל
            </span>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-envelope"></i>
            <span className="media-body"><a href="mailto:Maya@Alt-designes.com">Maya@Alt-delight.co.il</a></span>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-phone"></i>
            <span className="media-body"><a href="tel:+97254300322">054-20033332</a></span>
          </li>
          {/* End li */}
        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfoHeb;
