import React from "react";
import HeaderCookitRTL from "../../components/header/HeaderCookitRTL.jsx";
import ContactHeb from "../../components/contact/ContactHeb.jsx";
import FooterHeb from "../../components/footer/FooterHeb.jsx";
import ReactPlayer from 'react-player/lazy'
import ContactInfoHeb from "../../components/contact/ContactInfoHeb.jsx";


const cookitHeb = () => {
    return (
        <div className="main-left rtl-theme">
            <HeaderCookitRTL/> {/* End Header Section */}
            <section id="projects" className="b-white theme-light">
            <div dir="rtl">
              <div className="blog-grid">
                  <div>
                    <img src="https://res.cloudinary.com/dw3voimry/image/upload/v1660636048/Maya-web/cook-it-finished_fl2nve.webp" alt="blog post"></img>
                  </div>
                  {/* End blog-img */}
                  <article className="article">
                  <div className="article-title">
                    <h2 className="text-center">
                      <b>סקירה כללית</b>
                    </h2>
                    <div className="px-md-5 px-3 media mx-auto w-xl-50">
                      <div className="avatar">
                        <img src="/favicon-96x96.png" alt="תמונה ממוזערת" />
                      </div>
                      <div className="heb media-body mx-auto">
                        <label>מאת Maya Alt</label>
                        <span> 29 בפברואר 2022</span>
                      </div>
                    </div>
                  </div>
                    {/* End .article-title */}

                  <div className="article-content ">
                    <div>
                    <p className="px-md-5 px-3 mx-auto w-xl-50">
                      "Cookit" היא אפליקציה ואתר משלוחי ארוחות שנועדו לעזור לאנשים לענות על הצרכים התזונתיים שלהם תוך חיסכון בזמן על ידי תכנון והצעת הכמות המדויקת הדרושה, כך שאף מזון לא הולך לפח. כל זאת על ידי הצעת מגוון רחב ומגוון של ארוחות אופציונליות.
                    </p>
                    <p className="px-md-5 px-3 mx-auto w-xl-50">
                      בסיפור זה, אספק תובנות לגבי תהליך החשיבה שלי, השיטות והכלים בהם השתמשתי, ואסביר את ההגיון והמחשבות שלי מאחורי ההחלטות שקיבלתי.
                    </p>
                    <p className="px-md-5 px-3 mx-auto w-xl-50">
                      <small><b>התפקיד שלי: עיצוב UX\UI</b></small>
                    </p>
                    </div>
                    <h2 className="pb-3 text-center">
                      <b>רק טעימה קטנה</b>
                    </h2> 
                    <div className="pb-5">
                      <ReactPlayer height="850px" width="100%" url='https://youtu.be/5qrd5r2DcvE' autoplay="true" playing="true" loop="true" controls="true" config={{ file: { attributes: { controlsList: 'nodownload'}}}}/>
                    </div>
                      <div className="row">
                        <div>
                          <h3 className="px-md-5 px-3 mx-auto w-xl-50">תהליך העיצוב שלי</h3>
                          <p className="px-md-5 px-3 mx-auto w-xl-50">תהליך עיצוב מספק לי מסגרת כיצד להתמודד בצורה הטובה ביותר עם בעיה על ידי פירוק שלה לשלבים קטנים יותר.</p>
                        </div>
                        <div className="Bg-white100 text-center">
                          <img src="/img/blog/process.png" alt="process-design"/>
                        </div>
                      </div>
                      <div>
                        <h3 className="px-md-5 px-3 mx-auto w-xl-50">הבעיה</h3>
                        <p className="px-md-5 px-3 w-xl-50 mx-auto">אתה יכול לכתוב כאן כמה שאתה רוצה, הטקסט הזה תמיד ייראה נחמד, בין אם אתה כותב פסקאות ארוכות יותר או רק כמה מילים. לחץ כאן ונסה זאת.</p>
                      </div>
                      <div className="pt-3 pb-3 mt-5 mb-5 px-md-5 px-3 Bg-green25">
                        <h3 className="px-0 py-3 h3-st m-0">למה זה?</h3>
                      </div>
                      <div className="row">
                        <div className="col-xl-5 order-2 order-xl-1">
                          <h4 className="pr-2p px-md-5 px-3">קניות מקוונות</h4>
                            <p className="pr-2p px-md-5 px-3">קניית מצרכים באינטרנט עדיין גוזלת זמן רב מכיוון שאנו עדיין צריכים לבדוק כל פריט ברשימה שלנו, גם אם שמרת הזמנות בעבר שביצעת. כמו כן, החנויות מנסות למכור מרכיבים ספציפיים כדי להגדיל את הרכישה.</p>
                            <p className="pr-2p px-md-5 px-3">כל זה מאלץ את המשתמש לקבל יותר החלטות- יותר קבלת החלטות פירושה יותר זמן.</p>
                            <p className="pr-2p px-md-5 px-3">בעיה נוספת היא זמן האספקה; אין הרבה זמינות לשעות המשלוח, כך שהמשתמשים יוצאים מגדרם כדי לקבל את המשלוח. ואחרי כל זה, אנחנו עדיין יכולים לעשות טעויות ולשכוח מרכיב, או שאנחנו לא מקבלים אותו בכלל כי אזל המלאי. כך או כך, אנחנו תקועים בלי אלמנט שהיה חשוב לארוחה שלנו.</p>
                        </div>
                        <div className="text-center mb-5p col-xl-7 order-1 order-xl-2">
                          <img className="pt-4" src="/img/blog/products.gif" alt="products-design"/>
                        </div>
                      </div>
                      <div className="row">
                        <div className="text-center col-xl-7">
                          <img className="food-wasted mobilept-4p" src="/img/blog/food_wasted.png" alt="בזבוז-מזון"/>
                        </div>
                        <div className="col-xl-5">
                          <h4 className="pt-20p pr-2p px-md-5 px-3">מצרכים קונים לפי משקל</h4>
                          <p className="pr-2p px-md-5 px-3">המצרכים שאנו קונים נמדדים לפי משקל ולא לפי מנות. זה נראה מינורי, אבל כל אחד מבני המשפחה אוכל קצת אחרת, אנחנו קונים את האוכל לכולם, אבל אנחנו לא אוכלים את כולו בגלל העדפות אישיות שונות או סיבות בריאותיות. כתוצאה מכך, אנחנו קונים יותר ממה שאנחנו צריכים, מוציאים יותר כסף והרבה מהאוכל הולך לפח. FAO פרסם מחקר שמראה ששליש מכל המזון המיוצר בעולם הולך לאיבוד או הולך לפח, ובמדינות בעלות הכנסה בינונית עד גבוהה, מזון מבוזבז ואובד בעיקר בשלבים מאוחרים יותר ברמת הקמעונאות והצרכנים.</ p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 order-2 order-xl-1">
                          <h4 className="pt-20p pr-2p px-md-5 px-3 mt-18p">התפיסה שלנו לגבי אוכל</h4>
                          <p className="pr-2p px-md-5 px-3">קרה לך פעם שהלכת למקרר, יש שם כמה מרכיבים, אבל זה לא משתלב? ובכן... זה בגלל שהם לא.</p>
                          <p className="pr-2p px-md-5 px-3">אנחנו חושבים על מרכיבים במקום על ארוחות, ותכנון הארוחות שלך לוקח זמן - אם אתה רוצה קצת מגוון, זה לוקח אפילו יותר זמן.</p>
                          <p className="pr-2p px-md-5 px-3">זה גם תוצאה של יותר מדי החלטות לקבל- כל התהליך של חשיבה על ארוחות ופירוקן למרכיבים ואז לנסות לבנות אותם מחדש לארוחה הופך את התהליך מסובך וגוזל זמן.</p>
                        </div>
                        <div className="text-center mb-5p col-xl-6 order-1 order-xl-2">
                          <img className="food-wasted pt-20p" src="/img/blog/think-different.gif" alt="products-design"/>
                        </div>
                      </div>
                      <div className="text-center">
                      <span className="f-size3r text-black">או בקיצור</span>
                      </div>
                      <div className="mb-5p px-md-5 px-3 Bg-green25">
                        <p className="f-size2r f-italian text-center pt-2p text-white">הזמן שלוקח לקבל החלטה גדל עם מספר הבחירות ומורכבותן.</p>
                        <small className="text-center ml-50p text-white">חוק היק</small>
                      </div>
                      <div>
                      <h3 className="px-md-5 px-3 mx-auto w-xl-50">התוצאה</h3>
                      <p className="px-md-5 px-3  mx-auto w-xl-50">הלקוח קנה יותר ממה שהיה צריך, בזבז יותר כסף וכך יצא שרוב השאריות הולכות לפח.</p>
                      </div>
                      <div>
                      <h3 className="px-md-5 px-3 mx-auto w-xl-50">האתגר</h3>
                      <p className="px-md-5 px-3  mx-auto w-xl-50">האתגר העיקרי נובע מכך שאין מוצר כזה בישראל, והרעיון אינו מוכר למשתמשים ישראלים. אתגר נוסף הוא לקחת את התפיסה השוויצרית של משלוח ערכות ארוחה שזכתה להצלחה רבה בארה"ב ובשווקים אחרים באירופה ולשנות אותה לשוק הישראלי.</p>
                      </div>
                      <div>
                      <h3 className="px-md-5 px-3 mx-auto w-xl-50">היעד שלי</h3>
                      <p className="px-md-5 px-3 mx-auto w-xl-50">המטרה שלי היא שהמשתמש ישלים את כל התהליך - מקניית הארוחות - ועד להכנתן בבית בהצלחה.</p>
                      </div>
                      <div>
                      <h3 className="px-md-5 px-3 mx-auto w-xl-50">מחקר</h3>
                      <p className="f-para pr-2p m-auto w-xl-50 px-md-5 px-3">ניתחתי את המתחרים ואפליקציות דומות אחרות בתעשיות שונות עבור זרימות ספציפיות. למדתי את החוזקות והחולשות של כל חברה. </p>
                      </div>
                      
                      <div>
                      <p className="px-md-5 px-3  mx-auto w-xl-50"><b>ניתוח המתחרה שלי הניב את התובנות הבאות:</b></p>
                      <ul className="px-md-5 px-3  mx-auto w-xl-50">
                        <li className="pb-2p mr-5p">האפליקציה/אתר צריכים להראות את הערך שלו תוך הדגשת נקודות הכאב של הדמות הראשית. ההבנה של איך זה עובד צריכה להיות ברורה וישירה.</li>
                        <li className="pb-2p mr-5p">אנו יכולים לחזק את האמינות שלנו על ידי הצגת סרטונים של לקוחות שניסו את המוצר, ביקורות, שאלות נפוצות, מי אנחנו ומדוע יצרנו את המוצר הזה, שירות לקוחות ופיצוי או החזר במקרה של בעיה כדי לעודד את המשתמשים להצטרף לשירות.</li>
                        <li className="pb-2p mr-5p">המטרה שלי מושגת רק לאחר הכנה מוצלחת של כל הארוחה - לכן, עלינו להפוך את החוויה כולה לשלמה ככל האפשר על ידי הפיכת המתכון לאינטראקטיבי ופישוט המתכון . על ידי פירוק זה לשלבים עם הוראות וידאו, טיימרים אוטומטיים והפעלה קולית עקב חוסר נגישות לידיים בזמן הבישול.</li>
                        <li className="pb-2p mr-5p">דרוש מודול ליצירת דחיפות עבור המשתמשים להתחיל להשתמש במוצר. ישנן אפשרויות רבות כמו קופון הנחה לזמן מוגבל, הנחה לרכישות ראשונות, הבאת חבר לזיכוי ועוד. כמו כן, צריכה להיות קריאה אחת לפעולה כדי לדחוף את המשתמש לכיוון הנכון בזמן שעדיין יש לנו את תשומת הלב של המשתמש. </li>
                      </ul>
                      </div>
                      <div>
                      <h3 className="px-md-5 px-3  mx-auto w-xl-50">מחקר משתמשים</h3>
                      <p className="px-md-5 px-3  mx-auto w-xl-50">כדי לעצב אתר עם חווית משתמש מעולה, הייתי צריך לזהות את הצרכים של קהל היעד. ערכתי ראיונות משתמשים וסקר כדי למצוא את נקודות הכאב והצרכים העיקריים שלהם. היה חשוב לבקש ממבנים משפחתיים שונים להבין את נקודת המבט שלהם ולמי אני יכול לתת את הערך הרב ביותר ולהתמקד בפרסונה הזו. לאחר השלמת הראיונות, רשמתי את כל התגובות והתחלתי לקבץ אותן לפי הנושא.</p>
                      </div>
                      <div>
                      <h3 className="px-md-5 px-3  mx-auto w-xl-50">אישיות עיקרית</h3>
                      <p className="px-md-5 px-3  mx-auto w-xl-50">לאחר מכן, השתמשתי בכל הנתונים שאספתי במהלך תהליך המחקר כדי ליצור 'Ori' - דמות המשתמש שלי. אורי הוא מפתח תוכנה בחברה קטנה.</p>
                      </div>
                      
                      <div className="Bg-white100">
                        <img src="/img/blog/persona.png" alt="persona-design"/>
                      </div>
                      <div className="row">
                        <div>
                        <h3 className="px-md-5 px-3  mx-auto w-xl-50">זרימת משתמש</h3>
                      <p className="px-md-5 px-3  mx-auto w-xl-50">כדי להבין עד כמה הפתרון שלי יהיה בר-קיימא, ובהתבסס על מחקר המשתמש והסקר, יצרתי קו עלילה גס. כחלק מזרימת המשתמשים הזו, התחלתי למפות את המסכים וכיצד המשתמש עשוי לגלות את השירות.</p>
                        </div>
                      </div>
                      
                      <div className="Bg-white100">
                        <img className="pr-10p" src="/img/blog/user-flow.png" alt="user-flow-design"/>
                      </div>
                      <div className="row">
                        <div>
                        <h3 className="px-md-5 px-3  mx-auto w-xl-50">סקיצות</h3>
                      <p className="px-md-5 px-3  mx-auto w-xl-50">אחרי שהבנתי מהי זרימת המשתמש בפרויקט שלי, יצרתי סקיצות בנאמנות נמוכה. זו הדרך שבה אני חוזר על אפשרויות רבות במהירות ומנסה רעיונות שונים.</p>
                        </div>
                        <img src="/img/blog/sketches.png" alt="user-flow-design"/>
                      </div>
                      
                      <div className="row">
                        <div>
                        <h3 className="px-md-5 px-3  mx-auto w-xl-50">רעיונות ניווט שונים</h3>
                      <p className="px-md-5 px-3  mx-auto w-xl-50"> ניסיתי רעיונות ניווט שונים עבור האפליקציה לנייד ולאחר מכן המשכתי לבנות את שני המוצרים יחד כדי שיהיה עקבי.</p>
                        </div>
                        <div className="pt-5p ml-5pr Bg-white100">
                        <img className="pr-10p m-fix" src="/img/blog/diff-idea.png" alt="different-ideas-design"/>
                      </div>
                      </div>
                      
                      <div>
                      <h3 className="px-md-5 px-3  mx-auto w-xl-50">זרימות Wireframe</h3>
                      <p className="px-md-5 px-3  mx-auto w-xl-50">זו הייתה התוצאה הסופית של זרימת המשתמש לאחר כמה וריאציות ב-Axure.</p>
                      <p className="px-md-5 px-3  mx-auto w-xl-50">ערכתי את כל הדפים כדי שאוכל לדמיין כיצד כל חלק קשור אחד לשני וכיצד משתמש ייגש לכל חלק באפליקציה ובאתר.</p>
                    </div>
                    <div className="pt-5p Bg-green25">
                        <img className="m-width-95" src="/img/blog/proto.png" alt="prototype-design"/>
                      </div>
                  </div>  
                  <div className="row">
                  <div className="px-md-5 px-3 mx-auto w-xl-50 col-xl-6 order-2 order-xl-1">
                          <h4 className="pt-10p px-md-5 px-3">זמן בישול</h4>
                          <p className="px-md-5 px-3">תזכורת מהירה היא שהמטרה היא שהמשתמש ישלים את כל התהליכים החל מקניית הארוחות ועד להכנתן בבית בהצלחה. לכן עלינו לחשוב על אלמנטים אחרים שיכולים לשפר או לפגוע בחוויה.</p>
                          <p className="px-md-5 px-3">כדי לשפר את החוויה בתהליך הבישול, הוספתי פקודה קולית לדף המתכונים לאפשרויות כמו המשך, התחלת טיימר ועוד כדי להפוך את החוויה לשלמה.</ p>
                        </div>
                        <div className="col-xl-6 my-text-right order-1 order-xl-2">
                        <img className="M-W60p" src="/img/blog/cooking-time.gif" alt="cooking-time-design"/>
                      </div>
                      </div>  
                      <div className="row pt-7p pb-5p order-2 order-xl-1">
                        <div className="ml-18p-fix my-text col-xl-6">
                          <img className="M-W60p" src="/img/blog/order-made-easy.gif" alt="oreder-made-easy-design"/>
                        </div>
                        <div className="col-xl-6 order-1 order-xl-2">
                            <h4 className="pt-10p px-md-5 px-3">הזמנה מחדש בקלות</h4>
                            <p className="px-md-5 px-3">כפי שאמרתי קודם, כדי שהמשתמש יוכל להזמין בקלות ובמהירות, צריכות להיות אפשרויות מוגבלות, אבל במחקר המשתמשים גיליתי שגמישות היא חיונית לאירועים, סופי שבוע ו למקרה שהם רק רוצים משהו אחר.</p>
                            <p className="px-md-5 px-3">הזמנות מחדש ייווצרו אוטומטית בהתאם להעדפות המשתמש ולצרכים התזונתיים (באמצעות מסננים).</p>
                            <p className="px-md-5 px-3">ניתן לערוך אותו פשוט על ידי שימוש באפליקציה\אתר, אם אתה מצפה לחברה או רוצה לנסות משהו חדש.</p>
                        </div>
                      </div>
                    {/* End article content */}
                    <ul className="nav tag-cloud">
                      <li >עיצוב</li>
                      <li >פיתוח</li>
                      <li >אפיון</li>
                      <li >עיצוב אתרים</li>
                      <li >תוכן</li>
                      <li >מחקר</li>
                      <li >ניהול</li>
                    </ul>
                    {/* End tag */}
                  </article>
                  {/* End Article */}
                  <div className="row bg-ma">
                  <div className="pr-10p mb-5p col-xl-4">
                            <div className="pt-10p">
                                <h3>מאיה אלט</h3>
                                <small>מעצבת UX/UI</small>
                            </div>
                            <div className="mt-15p">
                                <span className="contact-1">
                                    <a className="mr-5p ml-5pr" href="#contactus">צור קשר</a>
                                </span>
                                <span className="resume-1">
                                    <a className="mr-5p ml-5pr" href="/resume.pdf">קורות החיים שלי</a>
                                </span>
                            </div>
                        </div>
                    <div className="pt-4 pb-2 mpt-10 col-xl-8">
                      <h3 className="text-center pb-1p">עוד עבודות שלי</h3>
                      <a href="/pawfactHeb">
                        <div className="my-bgnheb bg-w">
                          <img src="https://res.cloudinary.com/dw3voimry/image/upload/v1660206120/Maya-web/pawfact-background.webp" alt="pawect-post"/>
                          <h4 className="Pawfect-h4">PAWFECT</h4>
                        </div>
                      </a>
                    </div>
                  </div>
                  {/* End .contact Form */}
              </div>
          </div>
            </section>
            <section id="contactus" className="section theme-light dark-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-xl-4 m-15px-tb" data-aos="fade-right" data-aos-duration="1200">
                            <ContactInfoHeb/>
                        </div>
                        {/* End Contact info */}

                        <div className="col-lg-7 ml-auto m-15px-tb" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                            <div className="contact-form">
                                <h4>צור קשר</h4>
                                <ContactHeb/>
                            </div>
                        </div>
                        {/* End contact form */}

                        {/* End Col */} </div>
                </div>
            </section>
            {/* End Contact Section */}

            <footer className="footer white">
                <div className="container">
                    <FooterHeb/>
                </div>
            </footer>
            {/* End Contact Section */} </div>
    );
};

export default cookitHeb;
